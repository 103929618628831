import { createRouter, createWebHashHistory } from 'vue-router';
import Login from './components/Login.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./components/Dashboard.vue'),
    },
    {
        path: '/plan',
        name: 'plan',
        component: () => import('./components/Plan.vue'),
    },
    {
        path: '/budget',
        name: 'budget',
        component: () => import('./components/Budget.vue'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
