<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span>PLANNER</span>
		</router-link>
		<div  v-if="currentUser">
			<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
				<i class="pi pi-bars"></i>
			</button>
		</div>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top" v-if="currentUser">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-plus"></i>
					<span>New Transaction</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-history"></i>
					<span>Show History</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="logout">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul>
	</div>

</template>

<script>
export default {
	components: {
	},
	data() {
		return {
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		logout() {
			this.$store.dispatch("auth/logout").then(
				() => {
					this.$router.push("/login")
				},
				(error) => {
					console.log(error)
				}
			)
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		currentUser() {
			return this.$store.state.auth.user;
		}
	}
}
</script>
