<template>
  <div class="grid justify-content-center">
    <div class="card">
      <div class="flex align-items-center justify-content-center">
        <div class="p-fluid">
          <h3>Login</h3>
          <Divider layout="horizontal" align="center"></Divider>
          <div class="mb-3 p-2 border-1 border-pink-500 w-17rem border-round text-pink-500" align="center" :class="{'hidden': !loginError}">Looks like there was an issue, please try again</div>
          <div class="field">
            <label for="username">Username</label>
            <InputText id="username" v-model.trim="login.username" type="text" required="true" :class="{'p-invalid': submitted && !login.username} "/>
          </div>
          <div class="field">
            <label for="password">Password</label>
            <InputText id="password" v-model.trim="login.password" type="password" required="true" :class="{'p-invalid': submitted && !login.password} "/>
          </div>
          <Button label="Login" class="mt-2" @click="handleLogin"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        submitted: false,
        loginError: false,
        login: {
          username: '',
          password: ''
        }
      }
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn
      }
    },
    created() {
      if (this.loggedIn) {
        this.$router.push("/plan")
      }
    },
    methods: {
      handleLogin() {
        this.loginError = false
        this.submitted = true
        if (this.login.username.trim() && this.login.password) {
          this.$store.dispatch("auth/login", this.login).then(
            () => {
              this.$router.push("/plan")
            },
            (error) => {
              this.loginError = true
              console.log(error)
            }
          )
        }
      }
    }
  }
</script>
