import axios from 'axios';

const API_URL = process.env.VUE_APP_BASEURL

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export default class AuthService {

  login(user) {
    return axios
      .post(API_URL + '/login', 'username=' + user.username + '&' + 'password=' + user.password, config)
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  // register(user) {
  //   return axios.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   });
  // }
}
